import React, { useEffect, useState } from 'react';
import {
  faFileAlt,
  faUsers,
  faPeopleGroup,
  faScrewdriverWrench,
  faUsersRectangle,
  faEnvelope,
  faChartSimple,
  faFilePdf,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { UserSession } from 'features/rosie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser } from 'store/slices/userSessionSlice';
import {
  getRosieUserListPath,
  getCreateRosieUserPath,
  getTxterListPath,
  getSclaAppListPath,
  getSealsLegacyAppListPath,
  getSealsAppListPath,
  getMyTxtUserListPath,
  getEmailTemplateListPath,
  getSclaAppStatsPath,
  getMyTxtUserStatsPath,
  getSealsAppStatsPath,
  getTxterStatsPath,
  getSclaInterestListPath,
  getSclaReaderAppListPath,
  getUploadSclaAppPdfPath,
  getApprenticeAppListPath
} from 'utils/navigation';
import { useAppSelector } from '../store/hooks';

/**
 * Interface side menu dropdown links
 */
export type DropdownLink = {
  title: string;
  icon: JSX.Element;
  active: boolean;
  children: {
    title: string;
    icon: JSX.Element;
    to: string;
    type: 'internal' | 'external';
  }[];
};

/**
 * Side Menu links
 */
const LINKS: DropdownLink[] = [
  {
    title: 'MY-TXT',
    icon: <FontAwesomeIcon icon={faUsersRectangle} />,
    active: false,
    children: [
      {
        title: 'User Account Stats',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        to: getMyTxtUserStatsPath(),
        type: 'internal',
      },
      {
        title: 'User Accounts',
        icon: <FontAwesomeIcon icon={faUsers} />,
        to: getMyTxtUserListPath(),
        type: 'internal',
      },
    ],
  },
  {
    title: 'TXTers',
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    active: false,
    children: [
      {
        title: 'Stats',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        to: getTxterStatsPath(),
        type: 'internal',
      },
      {
        title: 'TXTer Data',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getTxterListPath(),
        type: 'internal',
      },
    ],
  },
  {
    title: 'SCLA',
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    active: false,
    children: [
      {
        title: 'Stats',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        to: getSclaAppStatsPath('2024'),
        type: 'internal',
      },
      {
        title: 'Applications',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getSclaAppListPath(
          1,
          30,
          'createdOn',
          'desc',
          'all',
          '2024',
          'all',
        ),
        type: 'internal',
      },
      {
        title: 'School Presentation Interests',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getSclaInterestListPath(
          1,
          30,
          'createdOn',
          'desc',
          '2024',
          'all',
        ),
        type: 'internal',
      },
      {
        title: 'SCLA Readers',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        to: getSclaReaderAppListPath(
          1,
          30,
          'createdOn',
          'desc',
          'all',
          '2024',
          'all',
        ),
        type: 'internal',
      },

      {
        title: 'Upload Application Pdf',
        icon: <FontAwesomeIcon icon={faFilePdf} />,
        to: getUploadSclaAppPdfPath(),
        type: 'internal',
      },
    ],
  },
  {
    title: 'SEALS',
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    active: false,
    children: [
      {
        title: 'Applications',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getSealsAppListPath(),
        type: 'internal',
      },
    ],
  },
  {
    title: 'SEALS Legacy',
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    active: false,
    children: [
      {
        title: 'Stats',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        to: getSealsAppStatsPath(),
        type: 'internal',
      },
      {
        title: 'Legacy Applications',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getSealsLegacyAppListPath(),
        type: 'internal',
      },
    ],
  },
  {
    title: 'LABS',
    icon: <FontAwesomeIcon icon={faUserTie} />,
    active: false,
    children: [
      {
        title: 'Applications',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getApprenticeAppListPath(),
        type: 'internal',
      }
    ],
  },
];

const ADMIN_LINKS: DropdownLink[] = [
  {
    title: 'Emails',
    icon: <FontAwesomeIcon icon={faEnvelope} />,
    active: false,
    children: [
      {
        title: 'Templates',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: getEmailTemplateListPath(),
        type: 'internal',
      },
    ],
  },
  {
    title: 'Admin Tools',
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    active: false,
    children: [
      {
        title: 'Rosie User Management',
        icon: <FontAwesomeIcon icon={faUsers} />,
        to: getRosieUserListPath(),
        type: 'internal',
      },
      {
        title: 'Create User',
        icon: <FontAwesomeIcon icon={faUsers} />,
        to: getCreateRosieUserPath(),
        type: 'internal',
      },
    ],
  },
];

const READER_LINKS: DropdownLink[] = [
  {
    title: 'SCLA',
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    active: false,
    children: [
      {
        title: 'SCLA Reader Apps',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        to: getSclaReaderAppListPath(
          1,
          30,
          'createdOn',
          'desc',
          'all',
          '2024',
          'all',
        ),
        type: 'internal',
      },
    ],
  },
];

export default function useNavigationLinks() {
  const [links, setLinks] = useState<DropdownLink[]>([]);

  const user = useAppSelector(getUser);

  useEffect(() => {
    let mounted = true;

    function filterLinks(userData: UserSession) {
      if (userData.type === 'super-admin' || userData.type === 'admin') {
        setLinks([...LINKS, ...ADMIN_LINKS]);
      } else if (userData.type === 'scla-app-reader') {
        setLinks([...READER_LINKS]);
      } else {
        setLinks(LINKS);
      }
    }

    if (mounted && user) filterLinks(user);

    return () => {
      mounted = false;
    };
  }, [user]);

  const toggleLinkDropdown = (link: DropdownLink) => {
    // eslint-disable-next-line no-param-reassign
    link.active = !link.active;
    setLinks([...links]);
  };

  return { links, toggleLinkDropdown };
}
