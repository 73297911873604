import React, { Suspense, lazy } from 'react';
import Loader from 'components/Elements/Loader/Loader';
import { MainLayout } from 'components/Layout/MainLayout';
import { Navigate, Outlet } from 'react-router-dom';
import UserSessionProvider from 'providers/UserSessionProvider';
import RosieRoutes from 'features/rosie/routes';

const TxterRoutes = lazy(() => import('features/txters/routes'));
const SclaRoutes = lazy(() => import('features/scla/routes'));
const SclaReaderRoutes = lazy(
  () => import('features/scla/routes/SclaReaderRoutes'),
);
const SealsLegacyAppRoutes = lazy(
  () => import('features/seals-legacy/routes'),
);
const SealsAppRoutes = lazy(() => import('features/seals/routes'));
const MyTxtUsersRoutes = lazy(() => import('features/my-txt/routes'));
const EmailRoutes = lazy(() => import('features/email/routes'));
const LabsRoutes = lazy(() => import('features/labs/routes'));
/**
 * Renders the routes. A loader is shown if user data is being fetched.
 * A loader is shown for routes that are lazy loaded
 * @returns
 */
function App() {
  return (
    <UserSessionProvider>
      {({ user }) => (
        <MainLayout user={user}>
          <Suspense
            fallback={
              <Loader theme="light" isVisible fullscreen={false} />
            }
          >
            <Outlet context={{ user }} />
          </Suspense>
        </MainLayout>
      )}
    </UserSessionProvider>
  );
}

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'txters/*', element: <TxterRoutes /> },
      { path: 'scla/*', element: <SclaRoutes /> },
      { path: 'admin/*', element: <RosieRoutes /> },
      { path: 'seals/legacy/*', element: <SealsLegacyAppRoutes /> },
      { path: 'seals/*', element: <SealsAppRoutes /> },
      { path: 'my-txt/*', element: <MyTxtUsersRoutes /> },
      { path: 'emails/*', element: <EmailRoutes /> },
      { path: 'labs/*', element: <LabsRoutes/> },
      // { path: 'txt-labs/*', element: <TxtLabsRoutes /> },
      // { path: 'emails/*', element: <EmailRoutes /> },
      // { path: 'hackathon/*', element: <HackathonRoutes /> },
      // { path: 'scla/*', element: <SclaRoutes /> },
      // { path: 'hustlencode/*', element: <HustleNCodeRoutes /> },
      // { path: 'office/*', element: <AttendanceLogsRoutes /> },
      // { path: '', element: <Navigate to="/app/hackathon/volunteers" /> },
      // { path: '*', element: <Navigate to="/app/hackathon/volunteers" /> },
      {
        path: '',
        element: <Navigate to="/txters" />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/txters" />,
  },
];

export const sclaReaderProtectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'scla/*', element: <SclaReaderRoutes /> },
      {
        path: '',
        element: <Navigate to="/scla" />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/scla" />,
  },
];
