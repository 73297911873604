// auth
export const getLoginPath = () => '/';
// rosie
export const getRosieUserListPath = () => '/admin/users';
export const getRosieUserDetailsPath = (id: string) =>
  `/admin/users/details/${id}`;
export const getCreateRosieUserPath = () => '/admin/users/new';
// txters
export const getTxterListPath = () => '/txters';
export const getTxterStatsPath = () => '/txters/stats';
export const getTxterDetailsPath = (id: string) => `/txters/details/${id}`;
// scla
export const getSclaAppListPath = (
  page: number,
  limit: number,
  sortColumn: string,
  sortType: string,
  appStatus: string,
  year: string,
  school: string,
  search?: string,
) => {
  const schoolStr = encodeURIComponent(school);
  if (search) {
    return `/scla?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=${search}&appStatus=${appStatus}&year=${year}&school=${schoolStr}`;
  }

  return `/scla?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=&appStatus=${appStatus}&year=${year}&school=${schoolStr}`;
};

export const getSclaReaderAppListPath = (
  page: number,
  limit: number,
  sortColumn: string,
  sortType: string,
  appStatus: string,
  year: string,
  school: string,
  search?: string,
) => {
  const schoolStr = encodeURIComponent(school);
  if (search) {
    return `/scla/readers/?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=${search}&appStatus=${appStatus}&year=${year}&school=${schoolStr}`;
  }

  return `/scla/readers/?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=&appStatus=${appStatus}&year=${year}&school=${schoolStr}`;
};

export const getSclaInterestListPath = (
  page: number,
  limit: number,
  sortColumn: string,
  sortType: string,
  year: string,
  school: string,
  search?: string,
) => {
  const schoolStr = encodeURIComponent(school);
  if (search) {
    return `/scla/interests?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=${search}&year=${year}&school=${schoolStr}`;
  }

  return `/scla/interests?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=&year=${year}&school=${schoolStr}`;
};

export const getSclaAppStatsPath = (year: string) =>
  `/scla/stats?year=${year}`;
  export const getUploadSclaAppPdfPath = () => '/scla/upload-app-pdf';
// seals
export const getSealsLegacyAppListPath = () => '/seals/legacy?year=2022';
export const getSealsAppListPath = () => '/seals';
export const getSealsAppStatsPath = () => '/seals/legacy/stats?year=2022';
// labs
export const getApprenticeAppListPath = () => '/labs';
// my-txt
export const getMyTxtUserListPath = () => '/my-txt/users';
export const getMyTxtUserStatsPath = () => '/my-txt/users/stats';
export const getMyTxtUserDetailsPath = (id: string) =>
  `/my-txt/user/${id}/background`;
export const getMyTxtUserDetailsBackgroundPath = (id: string) =>
  `/my-txt/user/${id}/background`;
  export const getMyTxtUserContactLogsPath = (id: string) =>
  `/my-txt/user/${id}/contact-logs`;
export const getMyTxtUserDetailsAcademicsPath = (id: string) =>
  `/my-txt/user/${id}/academics/scla`;
export const getMyTxtUserDetailsProgramsPath = (id: string) =>
  `/my-txt/user/${id}/programs`;
export const getSclaAppDetailsPath = (id: string, appId: string) =>
  `/my-txt/user/${id}/academics/scla/${appId}`;

// emails
export const getEmailTemplateListPath = () => '/emails/templates';
export const getEmailTemplateDetailsPath = (id: string) =>
  `/emails/template/${id}`;
export const getCreateEmailTemplatePath = () => '/emails/templates/create';
